import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  motion, AnimatePresence, easeIn, easeInOut,
} from 'framer-motion';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Donate from '../Donation/Donate';
import hero1 from '../../assets/background/bg_1.png';
import hero2 from '../../assets/background/bg_2.png';
import hero3 from '../../assets/background/bg_3.png';
import hero4 from '../../assets/background/bg_4.png';
import hero5 from '../../assets/background/bg_5.png';
import styles from './LandingPage.module.scss';

const heroBg = [
  {
    title: '<span>Empowering</span> Teenagers to Soar Beyond Limits',
    text: 'We are a non-profit organization that empowers teenagers to thrive regardless of their background.',
    bg: hero1,
  },
  {
    title: '<span>Investing</span> in Teens for a Better Tomorrow',
    text: 'Leading the way in empowering teenagers to become the best version of themselves.',
    bg: hero2,
  },
  {
    title: '<span>Igniting</span> Young Minds, Fueling Future Leaders',
    text: 'We are committed to helping teenagers reach their full potential with the right mentorship.',
    bg: hero3,
  },
  {
    title: "<span>Nuturing</span> Today’s Teens, Shaping Tomorrow's World",
    text: 'We find purpose in providing teenagers with the tools they need to succeed in life.',
    bg: hero4,
  },
  {
    title: '<span>Together</span> We Can Make a Difference by Helping More',
    text: 'Help us help more teenagers to make positive impacts in their communities with their choices.',
    bg: hero5,
  },
];

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isActiveA, setIsActiveA] = useState(false);
  const [isActiveB, setIsActiveB] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact-us');
  };

  const imageVariants = {
    enter: (direction) => ({
      opacity: 0.5,
      x: direction > 0 ? 100 : -100,
      scale: 0.8,
      transition: {
        duration: 1,
        ease: easeInOut,
      },
    }),
    center: {
      zIndex: 1,
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 1,
      },
    },
    exit: (direction) => ({
      zIndex: 0,
      opacity: 0,
      x: direction < 0 ? 100 : -100,
      scale: 0.8,
      transition: {
        duration: 1,
        ease: easeIn,
      },
    }),
  };

  const titleVariants = {
    enter: {
      opacity: 0, x: '-100px', scale: 0.9,
    },
    center: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.8,
      },
    },
    exit: {
      opacity: 0, x: '100px', scale: 0.5,
    },
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % heroBg.length);
    setIsActiveB(true);
    setIsActiveA(false)
  }

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + heroBg.length) % heroBg.length);
    setIsActiveA(true);
    setIsActiveB(false);
  }

  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <AnimatePresence custom={1}>
        {heroBg.map((bg, index) => (index === currentSlide ? (
          <motion.img
            key={bg.title}
            src={bg.bg}
            alt="hero"
            className={styles.bgImg}
            custom={1}
            variants={imageVariants}
            initial="enter"
            animate="center"
            exit="exit"
          />
        ) : null))}
      </AnimatePresence>
      <AnimatePresence>
        {heroBg.map((bg, index) => (index === currentSlide ? (
          <>
            <motion.h1
              key={bg.title}
              className={styles.title}
              variants={titleVariants}
              initial="enter"
              animate="center"
              exit="exit"
              dangerouslySetInnerHTML={{ __html: bg.title}}
            />
            <motion.p
              key={bg.text}
              className={styles.text}
              variants={titleVariants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              {bg.text}
            </motion.p>
          </>
        ) : null))}
      </AnimatePresence>
      <motion.div
        className={styles.cta}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className={styles.donate}>
          <Donate onClick={handleButtonClick} button="Donate" />
        </div>
        <Link to="/about-us" className={styles.ctaLink}>
          Learn More
        </Link>
      </motion.div>
      <div className={styles.dots}>
        <div className={styles.slider}>
          <div className={styles.enum}>
            <span>{`0${currentSlide + 1}`}</span>
            {`/0${heroBg.length}`}
          </div>
          <div className={styles.liner} />
        </div>
        <FaArrowLeft className={`${styles.arrowLeft} ${isActiveA ? styles.active : ''}`} onClick={prevSlide} />
        <FaArrowRight className={`${styles.arrowRight} ${isActiveB ? styles.active : ''}`} onClick={nextSlide} />
      </div>
    </motion.section>
  );
}

export default LandingPage;