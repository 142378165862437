import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';	
import { RiMailCheckFill } from "react-icons/ri";
import { GiBugleCall } from "react-icons/gi";
import { FaMapLocationDot } from "react-icons/fa6";
import { useForm, ValidationError } from '@formspree/react';
import TopNav from '../../components/Nav/TopNav';
import styles from './Contact.module.scss';
import contact_top from '../../assets/background/contact_top.png';
import contact_img from '../../assets/images/contact_bg.png';

const contact = [
  {
    id: 1,
    icon: <RiMailCheckFill className={styles.icon} />,
    title: 'Email Us',
    text: 'Our team will respond to your enquiry shortly after reception',
    action: '<a href="mailto:support@blaizingteens.com">support@blaizingteens.com</a>',
  },
  {
    id: 2,
    icon: <GiBugleCall className={styles.icon} />,
    title: 'Call Us',
    text: 'We welcome your calls to provide clarity about our cause',
    action: '<a href="tel:+234812504791">+234 (814) 250 4791</a>',
  },
  {
    id: 3,
    icon: <FaMapLocationDot className={styles.icon} />,
    title: 'Visit Us',
    text: 'You are welcome to visit us or come for a tour at:',
    action: '<address>1 Enen Afaha, Uyo, Akwa Ibom State, Nigeria</address>',
  },

];

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [state, handleSubmit] = useForm('mnnqaqoo');
  if (state.succeeded) {
    navigate('/see-you-soon');
  }

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%', transition: { delay: 0.5, duration: 0.2 } }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      className={styles.container}
    >
      <TopNav title="Contact Us" img={contact_top} />
      <article className={styles.wrapper}>
        <section className={styles.divider}>
          <article className={styles.heading}>
            <h1 className={styles.title}>We look forward to making a difference with
              <span className={styles.shade}> your partnership with us</span>
            </h1>

            <form className={styles.form} onSubmit={handleSubmit}>
              <article className={styles.inputDiv}>
                <input id="name" name="name" type="text" placeholder="Name" className={styles.input} required />
                <ValidationError prefix="Name" field="name" errors={state.errors} />
                <input id="email" name="email" type="email" placeholder="Email" className={styles.input} required />
                <ValidationError prefix="Email" field="email" errors={state.errors} />
              </article>
              <div className={styles.inputDiv}>
                <select id="purpose" name="purpose" className={styles.select} required>
                  <option value="">Select an option</option>
                  <option value="volunteer">Volunteer</option>
                  <option value="sponsor">Sponsor</option>
                  <option value="donate">Donate</option>
                  <option value="other">Other</option>
                </select>
                <ValidationError prefix="Purpose of reaching out" field="purpose" errors={state.errors} />
                <input id="phone" name="phone" type="number" placeholder="Phone Number" className={styles.input} required />
                <ValidationError prefix="Phone number" field="phone" errors={state.errors} />
              </div>
              <textarea  id="message"  name="message" placeholder="Send us a message" className={styles.textarea} required />
              <ValidationError prefix="Message" field="message" errors={state.errors} />
              <button type="submit" className={styles.button} disabled={state.submitting}>Send</button>
            </form>
          </article>
          <img src={contact_img} alt="contact" className={styles.image} />
        </section>

        <article className={styles.content}>
          {contact.map((item) => {
            const {
              id, icon, title, text, action,
            } = item;

            return (
              <article key={id} className={styles.contact}>
                {icon}
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.text}>{text}</p>
                <div dangerouslySetInnerHTML={{ __html: action }} />
              </article>
            );
          })}
        </article>
      </article>
    </motion.div>
  )
}

export default Contact;
