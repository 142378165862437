import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './How.module.scss';
import vol_b from '../../assets/icons/icon_vol_b.png';
import vol_a from '../../assets/icons/icon_vol_a.png';
import cha_b from '../../assets/icons/icon_cha_b.png';
import cha_a from '../../assets/icons/icon_cha_a.png';
import edu_b from '../../assets/icons/icon_edu_b.png';
import edu_a from '../../assets/icons/icon_edu_a.png';
import about_bg from '../../assets/images/about_bg.png';

const why = [
  {
    id: 1,
    title: 'Volunteering',
    text: "We're empowering and creating opportunities for teenagers to connect with their communities, build relationships, and contribute to local causes.",
    icon: [vol_a, vol_b],
  },
  {
    id: 2,
    title: 'Charity',
    text: 'We support those in need through fundraising, donations, and direct support services to address poverty, homelessness, lack of access to education, and healthcare.',
    icon: [cha_a, cha_b],
  },
  {
    id: 3,
    title: 'Education',
    text: 'Our programs are designed to inspire a lifelong love of learning as opportunities for teenagers to learn new skills, develop their talents, and prepare for a successful future.',
    icon: [edu_a, edu_b],
  },
];


const Why = ({ home }) => (
  <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <div className={styles.wrapper}>
      <motion.article
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
        className={styles.header}
      >
        {home && <p className={styles.subTitle}>About us</p>}
        <h2 className={styles.title}>How We Are
          <span className={styles.shade}> Making A Difference</span>
        </h2>
      </motion.article>

      <motion.article className={styles.content}>
        <motion.article
          className={styles.textContent}
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <p className={styles.text}>
            We're a catalyst for change. Our mission is to empower teenagers to reach
            their full potential by providing them with the tools, resources, and
            support they need to succeed. Through our innovative programs, we're
            fostering a generation of confident, compassionate, and capable young
            leaders who are making a positive impact on their communities and the
            world.
          </p>
          <img src={about_bg} alt="The Founder mentoring chatting with some teens in an outreach" className={styles.img} />
          <article className={styles.perksDiv}>
            {why.map((perk) => (
              <motion.article
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                className={styles.perk}
                key={perk.id}
                viewport={{ once: true }}
              >
                <div className={styles.iconDiv}>
                  <img src={perk.icon[1]} alt={perk.title} className={styles.icon} />
                  <img src={perk.icon[0]} alt={perk.title} className={styles.iconB} />
                </div>
                <div className={styles.div}>
                  <h5 className={styles.heading}>{perk.title}</h5>
                  <p className={styles.text}>{perk.text}</p>
                </div>
              </motion.article>
            ))}
          </article>
        </motion.article>
      </motion.article>
    </div>
    <img src={about_bg} alt="The Founder mentoring chatting with some teens in an outreach" className={styles.imgDesk} />
  </motion.section>
);

Why.defaultProps = {
  home: false,
};

Why.propTypes = {
  home: PropTypes.bool,
};

export default Why;
