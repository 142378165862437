import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './TopNav.module.scss';

const TopNav = ({ title, img }) => {
  return (
    <div className={styles.topSection} style={{ overflow: 'hidden' }}>
      <img
        src={img}
        alt={img}
        className={styles.topImage}
        preload="true"
      />
      <motion.h1
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className={styles.topHeader}
      >
        {title}
      </motion.h1>
    </div>
  );
};

TopNav.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default TopNav;
