import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import placeholder_vid from '../../assets/events/placeholder_vid.png';
import styles from './Empower.module.scss';
import Donate from '../Donation/Donate';
import YouTubeVideo from '../YTPlayer/YouTube';

const Empower = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact-us');
  }

  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <div className={styles.wrapper}>
        <motion.article
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
          viewport={{ once: true }}
          className={styles.header}
        >
          <h2 className={styles.title}>Empowering Our Teens
            <span className={styles.shade}> Shaping Tomorrow</span>
          </h2>
        </motion.article>

        <motion.article className={styles.content}>
          <motion.article
            className={styles.textContent}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className={styles.img}>
              <YouTubeVideo videoSrc="https://res.cloudinary.com/dn1ko8lbn/video/upload/v1728321575/blaizingTeens/unreached/documentation_kelevv.mp4" screen="small" />
            </div>
            <p>
              At Blazing Teens Initiative, we are dedicated to empowering teenagers
              to reach their full potential and make a positive impact on the world.
              Our causes reflect our commitment to providing essential support,
              resources, and opportunities for young people
            </p>
            <Donate button="Support Us" onClick={handleClick} />
          </motion.article>
          <YouTubeVideo videoSrc="https://res.cloudinary.com/dn1ko8lbn/video/upload/v1728321575/blaizingTeens/unreached/documentation_kelevv.mp4" />
        </motion.article>
      </div>
    </motion.section>
  );
};
// https://www.youtube.com/watch?v=-x4DRbCv_d4&t=9s

export default Empower;
