import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import TopNav from '../../components/Nav/TopNav';
import event_bg from '../../assets/background/event_top.png';
import Content from '../../components/CMS/Content';
import Testimonial from '../../components/Testimonial/Testimonial';
import Sponsor from '../../components/Sponsor/Sponsor';

const Event = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%', transition: { delay: 0.5, duration: 0.2 } }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <TopNav title="Event Details" img={event_bg} />
      <Content />
      <Testimonial />
      <Sponsor />
    </motion.div>
  )
}

export default Event;
