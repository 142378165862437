/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { TiMail, TiSocialFacebook } from 'react-icons/ti';
import { CiLocationOn } from 'react-icons/ci';
import { SlCallEnd } from 'react-icons/sl';
import styles from './Footer.module.scss';
import logoFooter from '../../assets/brand/logo_b.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <section className={styles.footerTop}>
        <article className={styles.subOne}>
          <article className={styles.logoDiv}>
            <img src={logoFooter} alt="Green Sterns Logo" className={styles.logo} />
            <small className={styles.text}>
              Blaizing Teens Initiative is a non-profit organization dedicated to empowering teenagers,
              especially young girls, to reach their full potential.
            </small>
          </article>
          <div className={styles.socialLinkDiv}>
            <h4 className={styles.title}>Follow Us</h4>
            <div className={styles.socialLinker}>
              <a
                href="https://www.instagram.com/blaizingteens"
                target="_blank"
                rel="noreferrer"
                className={styles.socialLink}
              >
                <FaInstagram className={styles.icon} />
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
                className={styles.socialLink}
              >
                <FaXTwitter className={styles.icon} />
              </a>
              <a
                href="https://web.facebook.com/profile.php?id=61555721150679&sk=about"
                target="_blank"
                rel="noreferrer"
                title="Ask us anything on Facebook"
                className={styles.socialLink}
              >
                <TiSocialFacebook className={styles.icon} />
              </a>
            </div>
          </div>
        </article>
        <div className={styles.location}>
          <h4 className={styles.title}>Get in Touch</h4>
          <div className={styles.footerLink}>
            <CiLocationOn className={styles.icon} />
            <span className={styles.text}>
              1 Enen Afaha Street,
              <br />
              Off Ikot Ekpene Road, Uyo,
              <br />
              Akwa Ibom State, Nigeria.
            </span>
          </div>
          <div className={styles.footerLink}>
            <SlCallEnd className={styles.icon} />
            <a href="tel:+234812504791" className={styles.text} style={{ color: '#fff' }}>
              +234 (814) 250 4791
            </a>
          </div>
          <div className={styles.footerLink}>
            <TiMail className={styles.icon} />
            <a
              href="mailto:blaizingteensinitiative90@gmail.com"
              className={styles.text}
              style={{ color: '#fff' }}
            >
              blaizingteensinitiative90@gmail.com
            </a>
          </div>
        </div>
      </section>
      <div className={styles.footerBottom}>
        <small className={styles.copyRight}>
          &copy;
          {` ${currentYear} `}
          Blaizing Teens, All Rights Reserved.
        </small>
      </div>
    </footer>
  );
};

export default Footer;
