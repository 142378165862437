import React from 'react';
import {  useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import pro_1 from '../../assets/images/pro_1.png';
import pro_2 from '../../assets/images/pro_2.png';
import pro_3 from '../../assets/images/pro_3.png';
import styles from './Causes.module.scss';

// ₦15,000,000
const causes = [
  {
    id: 1,
    title: 'reaching the unreached',
    text: "Provide comprehensive menstrual health education and resources to young girls through workshops, seminars, and distribution of menstrual hygiene products to promote self-care and self-awareness.",
    featureImg: pro_1,
    raised: 3512000,
    goal: 15000000,
    type: 'Education',
  },
  {
    id: 2,
    title: 'Smart Girls, Smooth Menses',
    text: 'Provide comprehensive menstrual health education and resources to young girls through workshops, seminars, and distribution of menstrual hygiene products to promote self-care and self-awareness.',
    featureImg: pro_2,
    raised: 487000,
    goal: 5000000,
    type: 'Health',
  },
  {
    id: 3,
    title: 'STEM for Blaizing-Teens',
    text: 'Empower students to explore the fields of Science, Technology, Engineering, and Mathematics through hands-on activities, workshops, and mentorship to encourage critical thinking and problem-solving.',
    featureImg: pro_3,
    raised: 6031000,
    goal: 50000000,
    type: 'Education',
  },
];

const Causes = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact-us');
  };
  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <div className={styles.wrapper}>
      <motion.article
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
        className={styles.header}
      >
        <p className={styles.subTitle}>Charity Projects</p>
        <h2 className={styles.title}>Preparing our teenagers
          <span className={styles.shade}> For A Better Tomorrow</span>
        </h2>
      </motion.article>

      <motion.article className={styles.content}>
        <motion.article
          className={styles.textContent}
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <p className={styles.text}>
            Together, we can create a brighter future for our youth. By joining hands with
            Blaizing Teens Initiative, you're investing in the next generation of leaders.
            Let's work together to empower teenagers to soar beyond their limits and make
            a positive impact on the world.
          </p>
          <article className={styles.perksDiv}>
            {causes.map((perk) => (
              <motion.article
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                className={styles.perk}
                key={perk.id}
                viewport={{ once: true }}
              >
                <img src={perk.featureImg} alt={perk.title} className={styles.featureImg} preload="true" />
                <div className={styles.div}>
                  <h5 className={styles.heading}>{perk.title}</h5>
                  <p className={styles.text}>{perk.text}</p>
                </div>
                <div className={styles.progress}>
                  <div className={styles.raised}><span>Raised: </span>₦{perk.raised.toLocaleString()}</div>
                  <div className={styles.goal}><span>Goal: </span>₦{perk.goal.toLocaleString()}</div>
                </div>
                <div className={styles.action}>
                  <button className={styles.button} onClick={handleButtonClick}>Donate</button>
                  <progress className={styles.trackerPercent} value={perk.raised} max={perk.goal} />
                  <p className={styles.trackerText}>{Math.floor((perk.raised / perk.goal) * 100)}% Funded</p>
                </div>
              </motion.article>
            ))}
          </article>
        </motion.article>
      </motion.article>
      </div>
  </motion.section>
  );
};

export default Causes;