import React from 'react';
import { Link } from 'react-router-dom';	
import { motion } from 'framer-motion';
import about_bg from '../../assets/background/how_bg.png';
import styles from './Help.module.scss';

const how = [
  {
    id: '01',
    title: 'Volunteer',
    text: 'Become a mentor, tutor, or event coordinator and make a lasting impact on the lives of young people. Share your time and talents to contribute to our programs and initiatives, helping us to provide essential services to teenagers in need.',
  },
  {
    id: '02',
    title: 'Donate',
    text: 'Make a difference with your donation. Support our programs and help us continue to empower teenagers to reach their full potential. Every donation counts. Your generosity will help us provide essential resources, and opportunities for young people.',
  },
  {
    id: '03',
    title: 'Mentor',
    text: 'Provide guidance and support to teenagers, helping them to overcome challenges, discover their passions, and achieve their goals. Be a positive role model and share your experiences to inspire young people to reach for the stars.',
  },
  {
    id: '04',
    title: 'Support',
    text: 'Join our team and help shape the future of Blazing Teens Initiative. Become a mentor and guide young people, sharing your knowledge, experience, and wisdom. Lead the way and inspire others to get involved.',
  },
];

const Help = () => {
  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <img src={about_bg} alt="background illustration" className={styles.bg} />
      <div className={styles.wrapper}>
        <motion.article
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
          viewport={{ once: true }}
          className={styles.header}
        >
          <p className={styles.subTitle}>How we help</p>
          <h2 className={styles.title}>We Can Achieve
            <span className={styles.shade}> More Your Help</span>
          </h2>
        </motion.article>

        <motion.article className={styles.content}>
          <motion.article
            className={styles.textContent}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <article className={styles.perksDiv}>
              {how.map((perk) => (
                <motion.article
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                  className={styles.perk}
                  key={perk.id}
                  viewport={{ once: true }}
                >
                  <div className={styles.iconDiv}>
                    <h3 className={styles.number}>{perk.id}</h3>
                  </div>
                  <div className={styles.div}>
                    <h5 className={styles.heading}>{perk.title}</h5>
                    <p className={styles.text}>{perk.text}</p>
                  </div>
                  <Link to="/about-us" className={styles.link}>Learn more</Link>
                </motion.article>
              ))}
            </article>
          </motion.article>
        </motion.article>
      </div>
    </motion.section>
  );
};

export default Help;
