import { MdKeyboardArrowLeft } from 'react-icons/md';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './Bod.module.scss';


const Member = ({ member, control }) => {

  return (
    <div
      className={styles.memberWrapper}
    >
      {
        member && (
          <div className={styles.coiner}>
            <AnimatePresence>
              <motion.div
                className={styles.member}
              >
                <div className={styles.splitter}>
                  {member.text.map((text, i) => (
                    <p key={i} className={styles.text}>
                      {text}
                    </p>
                  ))}
                  <h4 className={styles.name}>{member.name}</h4>
                  <h5 className={styles.role}>{member.role}</h5>
                  <button type="button" className={styles.iconDiv} onClick={(event) => control(null, event)}>
                    <MdKeyboardArrowLeft className={styles.icon} />
                  </button>
                </div>
                <img src={member.image} alt={member.name} className={styles.img} loading="lazy" />
              </motion.div>
            </AnimatePresence>
          </div>
        )
      }
    </div>
  );
};

Member.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    text: PropTypes.array.isRequired,
    image: PropTypes.string.isRequired,
    socials: PropTypes.any.isRequired,
    control: PropTypes.func.isRequired,
  }).isRequired,
};

export default Member;