import React from 'react';
import { motion } from 'framer-motion';
import styles from './Sponsor.module.scss';
import gci from '../../assets/sponsors/gci.png';
import taf from '../../assets/sponsors/taf.png';
import teens from '../../assets/sponsors/teens.png';
import elkanis from '../../assets/sponsors/elkan.png';
import danmert from '../../assets/sponsors/danmert.png';
import jwright from '../../assets/sponsors/jwright.png';

const sponsors = [
  {
    id: 1,
    name: 'The Girls Initiative',
    featureImg: gci,
  },
  {
    id: 2,
    name: 'Tim Atimore Foundation',
    featureImg: taf,
  },
  {
    id: 3,
    name: 'Teens Things',
    featureImg: teens,
  },
  {
    id: 4,
    name: 'Elkanis',
    featureImg: elkanis,
  },
  {
    id: 5,
    name: 'Danmert',
    featureImg: danmert,
  },
  {
    id: 6,
    name: 'Jhay Write',
    featureImg: jwright,
  },

];

const Sponsor = () => {
  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <div className={styles.wrapper}>
        <motion.article
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 }, stiffness: 100 }}
          viewport={{ once: true }}
          className={styles.header}
        >
          <p className={styles.subTitle}>SPONSORS & PARTNERS</p>
          <h2 className={styles.title}>They Help Build Bridges and,
            <span className={styles.shade}>  Guide Teens to Their Dreams</span>
          </h2>
        </motion.article>

        <motion.article className={styles.content}>
          <motion.article
            className={styles.textContent}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <article className={styles.perksDiv}>
              {sponsors.map((perk) => (
                <motion.img
                  src={perk.featureImg}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                  className={styles.featureImg}
                  key={perk.id}
                  alt={perk.title}
                  viewport={{ once: true }}
                />
              ))}
            </article>
          </motion.article>
        </motion.article>
      </div>
      <div className={styles.bg} />
    </motion.section>
  );
};

export default Sponsor;
