import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Why from '../../components/How/How';
import TopNav from '../../components/Nav/TopNav';
import about_bg from '../../assets/background/abt_top.png';
import OurDrive from '../../components/OurDrive/OurDrive';
import Sponsor from '../../components/Sponsor/Sponsor';
import Testimonial from '../../components/Testimonial/Testimonial';
import Involve from '../../components/Involve/Involve';
import Bod from '../../components/BOD/Bod';
import Volunteers from '../../components/BOD/Volunteers';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%', transition: { delay: 0.5, duration: 0.2 } }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <TopNav title="About Us" img={about_bg} />
      <Why />
      <OurDrive />
      <Bod />
      <Volunteers />
      <Involve />
      <Sponsor />
      <Testimonial />
    </motion.div>
  )
}

export default About;
