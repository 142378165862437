import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Donate from '../Donation/Donate';
import styles from './Involve.module.scss';

const Involve = () => {
  const navigate = useNavigate();
  const volunteerForm = () => {
    navigate('/contact-us')
  }

  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <div className={styles.wrapper}>
        <motion.article
          initial={{ opacity: 0, width: 0 }}
          whileInView={{ opacity: 1, width: '100%', transition: { duration: 0.5 } }}
          viewport={{ once: true }}
          className={styles.header}
        >
          <p className={styles.subTitle}>Get involved</p>
          <h2 className={styles.title}>Become a Volunteer</h2>
        </motion.article>
        <Donate onClick={volunteerForm} button="Volunteer With Us" />
      </div>
    </motion.section>
  );
};

export default Involve;
