import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styles from './Join.module.scss';
import about_bg from '../../assets/images/join_mob.png';
import join_desk from '../../assets/images/join_desk.png';

const why = [
  {
    id: 1,
    title: 'Our Mission',
    text: 'To help, teach, guide, and empower youngsters to soar beyond the limit of their counterparts, while fulfilling mandate, becoming pace setters and unleashing their extraordinary potentials around the globe, upholding Purity and Dignity.',
  },
  {
    id: 2,
    title: 'Our Vision',
    text: 'To reposition young minds towards the only Giver of Light, fostering their renewal as rising young eagles.',
  },
];

const Join = () => {
  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <div className={styles.wrapper}>
      <motion.article
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
        className={styles.header}
      >
        <p className={styles.subTitle}>why join us</p>
        <h2 className={styles.title}>We Believe In Every Child,
          <span className={styles.shade}> Helping As Many As We Can</span>
        </h2>
      </motion.article>

      <motion.article className={styles.content}>
        <motion.article
          className={styles.textContent}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <p className={styles.text}>
            We believe in every child. Our goal is to create a supportive environment where all teenagers feel valued and empowered. We offer guidance, mentorship, and resources to help them succeed, regardless of their background.
          </p>
          <img src={about_bg} alt="A volunteer distributing health supplies to some girls in an outreach" className={styles.img} />
          <article className={styles.perksDiv}>
            {why.map((perk) => (
              <motion.article
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                className={styles.perk}
                key={perk.id}
                viewport={{ once: true }}
              >
                <div className={styles.div}>
                  <h5 className={styles.heading}>{perk.title}</h5>
                  <p className={styles.text}>{perk.text}</p>
                </div>
              </motion.article>
            ))}
            <Link to="/about-us" className={styles.link}>More About Us</Link>
          </article>
        </motion.article>
      </motion.article>
    </div>
    <img src={join_desk} alt="A volunteer distributing health supplies to some girls in an outreach" className={styles.imgDesk} />
  </motion.section>
  );
};

export default Join;
