import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styles from './Content.module.scss';
import YouTubeVideo from '../YTPlayer/YouTube';
import eventData from './eventsData';

const Content = () => {
  const navigate = useNavigate();
  const [selectedPost, setSelectedPost] = useState(0);
  const location = useLocation();
  const decodedTitle = decodeURIComponent(location.pathname.split('/past-events/')[1]);

  const blog = eventData.find((item) => item.title === decodedTitle) || eventData[0];
  // const blog = blogData[selectedPost];

  const handleTitleClick = (index) => {
    setSelectedPost(index);
    const encodedTitle = encodeURIComponent(blog.title);
    navigate(`/past-events/${encodedTitle}`);
    window.scrollTo(0, 0);
  };

  return (
    <motion.div
      className={styles.container}
      style={{ overflow: 'hidden' }}
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <section className={styles.wrapper}>
        <div className={styles.contentDiv}>
          <div className={styles.eventDetails}>
            <div className={styles.heading}>
              <div className={styles.date}>
                <h5 className={styles.day}>{blog.day}</h5>
                <h5 className={styles.month}>{blog.month}</h5>
              </div>
              <h5 className={styles.title}>
                {blog.title}
              </h5>
            </div>

            <img src={blog.img} alt={blog.img} className={styles.img} />
            <div
              className={styles.textDiv}
            >
              {eventData[selectedPost].content.map((item, index) => (
                <div key={index} className={styles.danger}>
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.otherPosts}>
            <article className={styles.featuredPost}>
              <h3 className={styles.header}>Event Details</h3>
              <p className={styles.text}><span>Date: </span>{blog.eventDetails.date}</p>
              <p className={styles.text}><span>Type: </span> {blog.eventDetails.type}</p>
              <p className={styles.text}><span>Venue: </span>{blog.eventDetails.venue}</p>
              <p className={styles.text}><span>Attendees: </span>{blog.eventDetails.attendees}</p>
            </article>
            <ul className={styles.blogList}>
              <h3 className={styles.header}>Past Events</h3>
              {eventData.map((item, index) => (
                <li key={item.id} style={{ display: index === selectedPost ? 'none' : 'block'}} className={styles.linkItem}>
                  <Link
                    to={`/past-events/${item.title}`}
                    onClick={() => handleTitleClick(index)}
                    className={styles.link}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.mediaDiv}>
          <motion.article
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            viewport={{ once: true }}
            className={styles.header}
          >
            <p className={styles.subTitle}>Event Galley</p>
            <h2 className={styles.title}>memorable clicks and
              <span className={styles.shade}> videos from the event</span>
            </h2>
          </motion.article>
          <div className={styles.mediaContainer}>
            {blog.otherMedia.map((item, index) => {
              if (item.includes('youtube')) {
                return (
                  <YouTubeVideo key={index} videoSrc={item} />
                );
              }
              return (
                <img
                  key={index}
                  src={item}
                  alt={item}
                  className={styles.image}
                />
              );
            })}
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default Content;