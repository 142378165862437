/* eslint-disable linebreak-style */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './Contact.module.scss';
import thanks from '../../assets/thankmeme.mp4';

const ThankYou = () => {
  return (
    <motion.section
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
      className={styles.containee}
    >
      <article className={styles.content}>
        <video autoPlay loop muted className={styles.video}>
          <source src={thanks} type="video/mp4" />
        </video>
        <h4 className={styles.titleB}>We got your message!</h4>
        <p className={styles.text}>We will contact you shortly.</p>
      </article>
      <Link to="/" className={styles.btn}>Go back Home</Link>
    </motion.section>
  );
};

export default ThankYou;