import React from 'react';
import { motion } from 'framer-motion';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { TiSocialFacebook } from 'react-icons/ti';
import confidence from '../../assets/volunteers/confidence.svg';
import goodness from '../../assets/volunteers/goodness.svg';
import kenjosh from '../../assets/volunteers/kenjosh.svg';
import victoria from '../../assets/volunteers/victoria.svg';
import chioma from '../../assets/volunteers/chioma.svg';
import harrison from '../../assets/volunteers/harrison.svg';
import styles from './Volunteers.module.scss';

const volunteers = [
  {
    id: 1,
    job: 'Founder, Girl Child Initiative',
    name: 'Confidence Utomi',
    image: confidence,
    socials: {
      facebook: ['https://www.facebook.com/', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    }
  },
  {
    id: 2,
    job: 'Medical Doctor',
    name: 'Dr. Goodness Raji',
    image: goodness,
    socials: {
      facebook: ['https://www.facebook.com/raji.goodness?mibextid=LQQJ4d', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    },
  },
  {
    id: 3,
    job: 'Certified Teens Coach',
    name: 'KenJoshua Ibekwe',
    image: kenjosh,
    socials: {
      facebook: ['https://www.facebook.com/', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    },
  },
  {
    id: 4,
    job: 'Career Coach',
    name: 'Victoria Donald',
    image: victoria,
    socials: {
      facebook: ['https://www.facebook.com/', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/Victoriadonald5', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    }
  },
  {
    id: 5,
    job: 'Sexual Purity Advocate',
    name: 'Chioma Obetta',
    image: chioma,
    socials: {
      facebook: ['https://www.facebook.com/chioma.joy.9400984?mibextid=ZbWKwL', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    },
  },
  {
    id: 6,
    job: 'Professional Educator',
    name: 'Victor Harrison',
    text: [
      'Taiwo is a multifaceted professional with expertise in digital banking and entrepreneurship. He is currently a Regional Manager, Agency Banking at Parallex Bank, he oversees operations across South-South and South-East Nigeria. Additionally, he serves as CEO/MD of Danmert Nigeria Enterprise, empowering thousands of young people through financial inclusion and job creation.',
      'Holding an HND in Banking & Finance and BSc. in Accounting, Daniel is a Fellow of the National Institute of Credit Administration of Nigeria and student member of CIBN. ',
      'Driven by a passion for talent development, he has trained many young people and continues to inspire economic growth, happily married with three children.',
    ],
    image: harrison,
    socials: {
      facebook: ['https://www.facebook.com/', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    },
  },
];


const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const Volunteers = () => {

  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <div className={styles.wrapper}>
      <motion.article
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
        className={styles.header}
      >
        <p className={styles.subTitle}>VOLUNTEERS</p>
        <h2 className={styles.title}>Meet Our Amazing
          <span className={styles.shade}> Change Agents</span>
        </h2>
      </motion.article>

      <motion.article className={styles.content}>
        <motion.article
          className={styles.textContent}
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <p className={styles.text}>
            Our dedicated volunteers are the heart and soul of Blazing Teens Initiative.
            Their passion, commitment, and selfless service make a profound impact on
            the lives of our young people.
          </p>
          <article className={styles.perksDiv}>
            {volunteers.map((perk) => (
              <motion.article
                initial="isHidden"
                animate="isVisible"
                className={styles.perk}
                key={perk.id}
                viewport={{ once: true }}
                style={{ overflow: 'hidden' }}
                variants={animateBook}
                exit="isHidden"
                layoutId={perk.id}
                transit={perk.id * 0.2}
              >
                <div className={styles.iconDiv}>
                  <a
                    href={perk.socials.facebook[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    {perk.socials.facebook[1]}
                  </a>
                  <a
                    href={perk.socials.instagram[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    {perk.socials.instagram[1]}
                  </a>
                  <a
                    href={perk.socials.linkedin[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    {perk.socials.linkedin[1]}
                  </a>
                </div>
                <img src={perk.image} alt={perk.name} className={styles.img} />
                <div className={styles.base}>
                  <div className={styles.div}>
                    <h5 className={styles.heading}>{perk.name}</h5>
                    <p className={styles.text}>{perk.job}</p>
                  </div>
                </div>
              </motion.article>
            ))}
          </article>
        </motion.article>
      </motion.article>
    </div>
  </motion.section>
  )
}

export default Volunteers;
