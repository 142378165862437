import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MdMenu, MdClose } from 'react-icons/md';
import logo from '../../assets/brand/logo.png';
import styles from './Nav.module.scss';

const Nav = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact-us');
  };

  const [toggle, setToggle] = useState(false);

  const links = [
    {
      id: 1,
      name: 'Home',
      class: 'home',
      path: '/',
    },
    {
      id: 2,
      name: 'About',
      class: 'about',
      path: '/about-us',
    },
    {
      id: 3,
      name: 'Projects',
      class: 'causes',
      path: '/our-projects',
    },
    {
      id: 4,
      name: 'Events',
      class: 'events',
      path: '/past-events',
    },
    {
      id: 5,
      name: 'Contact',
      class: 'contact',
      path: '/contact-us',
    },
  ];

  const variants = {
    show: {
      opacity: 1,
      x: 0,
      transition: {
        x: { stiffness: 1000, velocity: 100 },
      },
    },
    hide: {
      x: -100,
      opacity: 0,
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const handleMenuOpen = () => {
    setToggle(!toggle);
  };

  const handleMenuClose = () => {
    setToggle(false);
  };

  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const sectionRefs = {
      home: '',
      about: '',
      causes: '',
      events: '',
      contact: '',
    };

    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 },
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        sectionObserver.observe(ref.current);
      }
    });

    return () => {
      sectionObserver.disconnect();
    };
  }, []);

  const handleNavigationClick = (section) => {
    setActiveSection(section);
  };

  return (
    <motion.header className={styles.header}>
      <motion.nav
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
        className={styles.nav}
      >
        {
          toggle ? (
            <MdClose onClick={handleMenuClose} className={styles.exitMenu} />
          ) : (
            <MdMenu
              onClick={handleMenuOpen}
              className={styles.mobileMenuIcon}
            />
          )
        }

        <Link to="/" className={styles.logo}>
          <img
            src={logo}
            alt="Blaizing Teens Initiative"
            className={styles.logoImg}
          />
        </Link>

        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={toggle ? 'show' : 'hide'}
          variants={variants}
          transition={{ type: 'spring', duration: 0.3, stiffness: 120 }}
          className={`${styles.navOverlay} ${toggle ? styles.show : styles.hide}`}
        >
          <motion.ul
            className={styles.navList}
          >
            {
              links.map((link) => (
                <motion.li
                  key={link.id}
                  className={`${styles.navItem} ${activeSection === link.class ? styles.active : ''}`} onClick={() => handleNavigationClick(link.class)}
                  whileHover={{ scale: 1.05, originX: 0, transition: { duration: 0.5 } }}
                  whileTap={{ scale: 0.95 }}

                >
                  <Link to={link.path} className={styles.navLink} onClick={handleMenuClose}>
                    {link.name}
                  </Link>
                </motion.li>
              ))
            }
          </motion.ul>
        </motion.div>

        <div className={styles.navBtns}>
          <div className={styles.navUser}>
            <button type="button" className={styles.btn} onClick={handleButtonClick}>
              Donate Now
            </button>
          </div>
        </div>

      </motion.nav>

      <Outlet />
    </motion.header>
  );
};

export default Nav;