/* eslint-disable arrow-body-style */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Nav from '../../components/Nav/Nav';
import Home from '../../pages/Home/Home';
import About from '../../pages/About/About';
import Contact from '../../pages/Contact/Contact';
import Causes from '../../pages/Causes/Causes';
import Event from '../../pages/Events/Event';
import Footer from '../../components/Footer/Footer';
import ThankYou from '../Contact/ThankYou';

const Layout = () => {
  return (
    <BrowserRouter>
      <Nav />
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/our-projects" element={<Causes />} />
            <Route path="/past-events" element={<Event />} />
            <Route path="/past-events/:id" element={<Event />} />
            <Route path="/see-you-soon" element={<ThankYou />} />
          </Routes>
        </AnimatePresence>
      <Footer />
    </BrowserRouter>
  );
};

export default Layout;