import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import styles from './YouTube.module.scss';

const YouTubeVideo = ({ videoSrc, screen }) => {
  return (
    <div className={styles.videoContainer}>
      <ReactPlayer
        url={videoSrc}
        width="100%"
        controls
        className={`${screen === 'small' ? styles.small : styles.large}`}
        lazy="true"
      />
    </div>
  );
};

YouTubeVideo.defaultProps = {
  screen: '',
};

YouTubeVideo.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  screen: PropTypes.string,
};

export default YouTubeVideo;
