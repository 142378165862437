import React from 'react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { TiSocialFacebook } from 'react-icons/ti';
import Member from './Member';
import abigail from '../../assets/bod/founder.svg';
import taiwo from '../../assets/bod/pat.svg';
import joshua from '../../assets/bod/josh.svg';
import styles from './Bod.module.scss';

const directors = [
  {
    id: 1,
    role: 'Founder/CEO',
    name: 'Abigail Chinwemdu O.',
    text: [
      'Abigail Chinwemdu Osuya, a dynamic entrepreneur and banker, founded Blaizing Teens Initiative with a passion for empowering young minds. Armed with a strong educational background and years of experience in the financial sector, she has created a supportive environment for teenagers to thrive.',
      'Her commitment to promoting sexual purity and financial literacy, coupled with her ability to connect with diverse audiences, has made Blaizing Teens Initiative a beacon of hope for countless youth across Nigeria.',
      "Under Abigail's leadership, the organization continues to inspire and uplift young people, providing them with the tools and resources they need to succeed.",
    ],
    image: abigail,
    socials: {
      facebook: ['https://www.facebook.com/', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    },
  },
  {
    id: 2,
    role: 'Finance Manager',
    name: 'Daniel Taiwo P.',
    text: [
      'Taiwo is a multifaceted professional with expertise in digital banking and entrepreneurship. He is currently a Regional Manager, Agency Banking at Parallex Bank, he oversees operations across South-South and South-East Nigeria. Additionally, he serves as CEO/MD of Danmert Nigeria Enterprise, empowering thousands of young people through financial inclusion and job creation.',
      'Holding an HND in Banking & Finance and BSc. in Accounting, Daniel is a Fellow of the National Institute of Credit Administration of Nigeria and student member of CIBN. ',
      'Driven by a passion for talent development, he has trained many young people and continues to inspire economic growth, happily married with three children.',
    ],
    image: taiwo,
    socials: {
      facebook: ['https://www.facebook.com/https://web.facebook.com/taiwo.p.daniel', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/taiwodaniel22', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/in/danieltaiwopego', <FaLinkedinIn className={styles.icon} />],
    },
  },
  {
    id: 3,
    role: 'Program Manager',
    name: 'Joshua Yousouph S.',
    text: [
      "Yousouph Joshua Similoluwa is a dedicated Maritime Journalist and Public Relations Professional with nearly five years of experience in news reporting, broadcasting, and strategic communication. Joshua holds a Bachelor's degree in Mass Communication from Adekunle Ajasin University, Ondo State, and is currently pursuing a Master's Degree in Communication Studies at Lagos State University. His academic foundation is complemented by his hands-on expertise in social media management, WordPress website management, and live broadcasting, making him a versatile communications professional.",
      'As the Director and Program Manager of Blaizing Teens Initiative, an NGO committed to empowering young people, Joshua plays a pivotal role in guiding teenagers to maximize their untapped potential. His leadership in the organization aligns with his personal values of integrity, excellence, and youth empowerment.',
      'He also serves as the CEO of JhayWrights Communications, a Public Relations and Advertising firm that also creates motivational content for social media and personal websites.',
    ],
    image: joshua,
    socials: {
      facebook: ['https://www.facebook.com/', <TiSocialFacebook className={styles.icon} />],
      instagram: ['https://instagram.com/', <FaInstagram className={styles.icon} />],
      linkedin: ['https://www.linkedin.com/', <FaLinkedinIn className={styles.icon} />],
    }
  },
];

const Bod = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const expandMemberInfo = (memberId, event) => {
    if (event){event.stopPropagation();}
    console.log(`You clicked on ${memberId}`);
    setSelectedMember((prevMember) => (prevMember === memberId ? null : memberId));
  };

  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <div className={styles.wrapper}>
      <motion.article
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
        className={styles.header}
      >
        <p className={styles.subTitle}>BOARD OF DIRECTORS</p>
        <h2 className={styles.title}>The Visionaries Driving
          <span className={styles.shade}> Blaizing Teens Initiative</span>
        </h2>
      </motion.article>

      <motion.article className={styles.content}>
        <motion.article
          className={styles.textContent}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <p className={styles.text}>
            Our experienced leaders are dedicated to empowering teenagers and guiding
            them towards a brighter future. Their diverse backgrounds and shared
            passion drive our mission.
          </p>
          <article className={styles.perksDiv}>
            {directors.map((perk) => (
              <motion.article
                initial={{ opacity: 0, x: '-100%' }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                className={styles.perk}
                key={perk.id}
                viewport={{ once: true }}
                style={{ overflow: 'hidden' }}
              >
                <div className={styles.iconDiv}>
                  <a
                    href={perk.socials.facebook[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    {perk.socials.facebook[1]}
                  </a>
                  <a
                    href={perk.socials.instagram[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    {perk.socials.instagram[1]}
                  </a>
                  <a
                    href={perk.socials.linkedin[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    {perk.socials.linkedin[1]}
                  </a>
                </div>
                <img
                  src={perk.image} 
                  alt={perk.name}
                  className={styles.img}
                />
                <div className={styles.base}>
                  <motion.button
                    whileHover={{ scale: 1.05, rotate: 10 }}
                    whileTap={{
                      scale: 0.8,
                      rotate: -90,
                      borderRadius: "25px 25px 0 0"
                    }}
                    type="button"
                    className={styles.btn}
                    onClick={(event) => expandMemberInfo(perk.id, event)}
                  >
                    <MdKeyboardArrowRight className={styles.button} />
                  </motion.button>
                  <div className={styles.div}>
                    <h5 className={styles.heading}>{perk.name}</h5>
                    <p className={styles.text}>{perk.role}</p>
                  </div>
                </div>
              </motion.article>
            ))}
          </article>
        </motion.article>
      </motion.article>
    </div>
    {selectedMember && (
      <Member member={directors[selectedMember - 1]} control={expandMemberInfo} />
    )}
  </motion.section>
  )
}

export default Bod;
