import React from 'react';
import PropTypes from 'prop-types';
import styles from './Donate.module.scss';

const Donate = ({ onClick, button }) => {

  return (
    <button
      type="button"
      className={styles.button}
      onClick={onClick}
    >
      {button}
    </button>
  );
};

Donate.propTypes = {
  onClick: PropTypes.func.isRequired,
  button: PropTypes.string.isRequired,
};

export default Donate;
