import React from 'react';
import { motion } from 'framer-motion';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import confidence from '../../assets/volunteers/confidence.svg';
import principal from '../../assets/images/principal.svg';
import styles from './Testimonial.module.scss';

const reviews = [
  {
    id: 1,
    name: 'Confidence Utomi',
    title: 'Founder, Girl Child Initiative',
    text: 'I have been a volunteer with Blaizing Teens Initiative for over a year now, and it has been an amazing experience. I have had the opportunity to work with some incredible young people who are passionate about making a difference in their communities. The team at Blaizing Teens Initiative is dedicated, supportive, and always willing to go the extra mile to help us succeed. I am proud to be a part of this organization and look forward to continuing to work with them in the future.',
    image: confidence,

  },
  {
    id: 2,
    name: 'Abasiama Udo',
    title: 'Student, Ukanafun',
    text: 'I am a beneficiary of the Blaizing Teens Initiative. I learned so much about leadership, teamwork, and community service, and I had the opportunity to meet some amazing people who have inspired me to be the best version of myself. The team at Blaizing Teens Initiative is supportive, caring, and always there to help us succeed. I am grateful for the opportunity to be a part of this organization and look forward to continuing to grow and learn with them.',
    image: 'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812000/blaizingTeens/unreached/IMG-20240207-WA0069_hafffc.jpg',
  },
  {
    id: 3,
    name: 'Joshua Okeke',
    title: 'School Principal, Ukanafun',
    text: 'I have had the pleasure of working with the Blaizing Teens Initiative on several projects, and I have been consistently impressed by their professionalism, dedication, and commitment to making a positive impact in the community. The team at Blaizing Teens Initiative is passionate, creative, and always willing to go the extra mile to ensure the success of their programs. I am proud to be associated with this organization and look forward to continuing to collaborate with them in the future.',
    image: principal,
  },
];

const configure = () => ({
  extraLarge: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },

  desktop: {
    breakpoint: { max: 3000, min: 1100 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1099, min: 768 },
    items: 2,
  },

  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
});

const Testimonial = () => {
  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <div className={styles.wrapper}>
        <motion.article
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
          viewport={{ once: true }}
          className={styles.header}
        >
          <p className={styles.subTitle}>Testimonials</p>
          <h2 className={styles.title}>See How your support is
            <span className={styles.shade}> Making A Difference</span>
          </h2>
        </motion.article>

        <article className={styles.reviewDiv}>
          <Carousel
            autoPlay
            autoPlaySpeed={5000}
            infinite
            showArrows
            showDots
            className="carousel"
            keyBoardControl
            responsive={configure()}
            style={{ overflow: 'hidden' }}
          >
            {reviews.map((review) => (
              <Card className="reviewCard" key={review.id}>
                <Card.Body className="reviewInfo">
                  <Card.Text className="reviewText">{review.text}</Card.Text>
                  <Card.Body className="reviewDetails">
                    <Card.Img variant="top" src={review.image} className="reviewImage" />
                    <Card.Body className="reviewFlex">
                      <Card.Title className="reviewName">{review.name}</Card.Title>
                      <Card.Text className="location">{review.title}</Card.Text>
                    </Card.Body>
                  </Card.Body>
                </Card.Body>
              </Card>
            ))}
          </Carousel>
        </article>
      </div>
    </motion.section>
  );
};

export default Testimonial;